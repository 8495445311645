import React from 'react';
import { filter } from 'lodash';
import { Icon, Pill } from 'src/components/shared';
import * as StylesPills from 'src/styles/workoutFilterPills.module.scss';

const Pills = ({ activeFilter, type, filters, removeFilter }) => {
    const thisFilter = filter(filters, { type })[0];
    const FilterPill = (id: string) => {
        const thisOption = filter(thisFilter.data, { id })[0];
        return (
            <Pill
                key={`${type}-${id}`}
                className={StylesPills.filterPill}
                bgcolor="black"
                color="white"
            >
                {thisOption.label}
                <Icon
                    onClick={removeFilter.bind(this, thisFilter, thisOption)}
                    name="pv-close"
                    color="white"
                    size={12}
                />
            </Pill>
        );
    };
    return activeFilter.map(FilterPill);
};

export default Pills;
