import React, { useRef, useState, useCallback } from 'react';
import cn from 'classnames';
import { Button, Grid, Dropdown, Checkbox } from 'semantic-ui-react';
import { find, isEmpty, map } from 'lodash';

import { Banner, Icon } from 'src/components/shared';
import classNames from 'classnames';
import Pills from 'src/components/workout-library/Pills';
import { WorkoutFiltersProps } from './WorkoutFiltersDrawer';
import {
    FilterItemsState,
    IFilterConfig,
    IFilterData,
    IFilterTypes,
} from '@pvolve/sdk/src/redux/selectors';
import { take, uniq } from 'lodash';

const HIDE_MENU_DELAY = 1500;

interface FilterBarProps extends WorkoutFiltersProps {
    activeItems: FilterItemsState;
    count: number;
    filterCount: number;
    isWhiteStyle?: boolean;
    isHiddenCounter?: boolean;
    clearFilters: () => void;
    onMenuItemClick: (
        filter: IFilterConfig,
        option: IFilterData,
        e: React.MouseEvent<HTMLInputElement, MouseEvent>
    ) => void;
    getIsOptionActive: (filterType: IFilterTypes, optionId: string) => boolean;
    removeFilter: (filter: IFilterConfig, option: IFilterData) => void;
    Styles: React.CSSProperties;
}

const sortFilterOptions = (a: any, b: any) => (a?.order || 1000) - (b?.order || 1000);

const WorkoutFilters = ({
    count,
    filters,
    filterCount,
    isWhiteStyle,
    isHiddenCounter,
    activeItems,
    removeFilter,
    clearFilters,
    onMenuItemClick,
    getIsOptionActive,
    Styles,
}: FilterBarProps) => {
    const dropdownRefs = [];
    const dropdownTimers = [];
    const onMenuOut = (index: number) => {
        dropdownTimers[index] = setTimeout(dropdownRefs[index]?.current?.close, HIDE_MENU_DELAY);
    };

    const onMouseOver = (index: number) => {
        clearTimeout(dropdownTimers[index]);
    };

    const MenuOption = (filter: IFilterConfig, option: IFilterData, optionIndex: number) => {
        const isActive = getIsOptionActive(filter.type, option.id);

        return (
            <Dropdown.Item
                key={`filter-option-${optionIndex}`}
                active={isActive}
                onClick={onMenuItemClick.bind(this, filter, option)}
            >
                <Checkbox checked={isActive} id={option.id} label={option.label} />
            </Dropdown.Item>
        );
    };

    const Menu = (filter: IFilterConfig, index: number) => {
        dropdownRefs.push(useRef());

        const onShowMore = useCallback((index, e) => {
            e.stopPropagation();
            setExpandedFilters((state) => [...state, index]);
        }, []);

        const onShowLess = useCallback((index, e) => {
            e.stopPropagation();
            setExpandedFilters((state) => {
                const newList = state.filter((item) => item !== index);
                return uniq(newList);
            });
        }, []);

        const [expandedFilters, setExpandedFilters] = useState<number[]>([]);
        const sortedOptions = filter.data
            .sort(sortFilterOptions)
            .map(MenuOption.bind(this, filter));
        const initialOptions = take(sortedOptions, 8);
        const moreOptions = sortedOptions.slice(8);
        const expanded = expandedFilters.includes(index);

        if (filter.hide && filter.hide.length) {
            return null;
        }
        return (
            <Dropdown
                key={`filter-${index}`}
                icon={{}}
                text={filter.label}
                ref={dropdownRefs[index]}
                className="accent"
                upward={false}
            >
                <Dropdown.Menu
                    onMouseOut={onMenuOut.bind(this, index)}
                    onMouseOver={onMouseOver.bind(this, index)}
                >
                    <div className={Styles.optionsWrapper}>
                        {initialOptions}
                        {moreOptions.length && expanded ? (
                            <div className={classNames(Styles.moreOptions, { expanded })}>
                                {moreOptions}
                                <div
                                    onClick={onShowLess.bind(this, index)}
                                    className={classNames(
                                        Styles.showMore,
                                        isWhiteStyle ? Styles.inverseText : ''
                                    )}
                                >
                                    <Icon name="pv-minus" size={26} /> Show less
                                </div>
                            </div>
                        ) : null}
                        {moreOptions.length && !expanded ? (
                            <div
                                onClick={onShowMore.bind(this, index)}
                                className={classNames(
                                    Styles.showMore,
                                    isWhiteStyle ? Styles.inverseText : ''
                                )}
                            >
                                <Icon name="pv-plus" size={26} /> Show {moreOptions.length} more
                            </div>
                        ) : null}
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        );
    };

    const hasActiveItems = (activeItems) => !!find(activeItems, (item) => !isEmpty(item));

    return (
        <Grid>
            <Grid.Row>
                <div className={`${Styles.filtersBar} ${isWhiteStyle ? Styles.white : ''}`}>
                    <div className={Styles.filters}>filter workouts {filters.map(Menu)}</div>
                </div>
            </Grid.Row>
            {hasActiveItems(activeItems) && (
                <Grid.Row>
                    <Grid.Column textAlign="center" width={16}>
                        {map(activeItems, (item, type: string, index: number) => {
                            return (
                                <Pills
                                    key={`pill-${index}`}
                                    activeFilter={item}
                                    type={type}
                                    removeFilter={removeFilter}
                                    filters={filters}
                                />
                            );
                        })}
                    </Grid.Column>
                    <Grid className={cn(Styles.statusRow, 'container')}>
                        <Grid.Column textAlign="center" width={5} />
                        <Grid.Column textAlign="center" width={6}>
                            {filterCount > 1 && (
                                <Button className={Styles.clearButton} onClick={clearFilters}>
                                    Clear All Filters
                                </Button>
                            )}
                        </Grid.Column>
                        {hasActiveItems(activeItems) && !isHiddenCounter && (
                            <Grid.Column textAlign="right" width={5}>
                                <p>{count} workouts</p>
                            </Grid.Column>
                        )}
                    </Grid>
                </Grid.Row>
            )}
            {hasActiveItems(activeItems) && !count && (
                <Grid.Row>
                    <Banner type="WARNING" className="align--center" withIcon>
                        No results found. Please revise filter selections.
                    </Banner>
                </Grid.Row>
            )}
        </Grid>
    );
};

export default WorkoutFilters;
