import React, { useCallback, useState } from 'react';
import { Button, Checkbox } from 'semantic-ui-react';
import { IFilterData, IFilterConfig, IFilterTypes } from '@pvolve/sdk/src/redux/selectors';
import { Icon } from 'src/components/shared';
import classNames from 'classnames';
import { take, uniq } from 'lodash';

const sortFilterOptions = (a: any, b: any) => (a?.order || 1000) - (b?.order || 1000);

export interface WorkoutFiltersProps {
    count: number;
    drawerVisible?: boolean;
    toggleDrawer?: () => void;
    filters: IFilterConfig[];
    filterCount: number;
    clearFilters: () => void;
    onMenuItemClick: (
        filter: IFilterConfig,
        option: IFilterData,
        e: React.MouseEvent<HTMLInputElement, MouseEvent>
    ) => void;
    getIsOptionActive: (filterType: IFilterTypes, optionId: string) => boolean;
    removeFilter: (filter: IFilterConfig, option: IFilterData) => void;
    Styles: React.CSSProperties;
    scrollToLibrary: () => void;
}

/**
 * Reference Implementation on mobile: mobile/src/components/workouts/Filters.tsx
 */
const WorkoutFiltersDrawer = ({
    count,
    drawerVisible,
    toggleDrawer,
    filters,
    filterCount,
    clearFilters,
    onMenuItemClick,
    getIsOptionActive,
    Styles,
    scrollToLibrary,
}: WorkoutFiltersProps) => {
    const [expandedFilters, setExpandedFilters] = useState<number[]>([]);
    const MenuOption = (filter: IFilterConfig, option: IFilterData, optionIndex: number) => (
        <Checkbox
            key={`filter-option-${optionIndex}`}
            onClick={onMenuItemClick.bind(this, filter, option)}
            checked={getIsOptionActive(filter.type, option.id)}
            id={option.id}
            label={option.label}
        />
    );

    const onShowPress = useCallback((index, expanded) => {
        setExpandedFilters((state) => {
            const newState = expanded ? [] : [...state, index];

            return uniq(newState);
        });
    }, []);

    const Menu = (filter: IFilterConfig, index: number) => {
        const sortedOptions = filter.data
            .sort(sortFilterOptions)
            .map(MenuOption.bind(this, filter));

        const initialOptions = take(sortedOptions, 8);
        const moreOptions = sortedOptions.slice(8);
        const expanded = expandedFilters.includes(index);

        if (filter.hide && filter.hide.length > 0) {
            return null;
        }
        return (
            <div key={index} className={Styles.optionsWrapper}>
                <h4 className="accent">{filter.label}</h4>
                {initialOptions}
                {moreOptions.length ? (
                    <div className={classNames(Styles.moreOptions, { expanded })}>
                        {moreOptions}
                    </div>
                ) : null}
                {moreOptions.length && !expanded ? (
                    <button
                        onClick={onShowPress.bind(this, index, expanded)}
                        className={Styles.showButton}
                    >
                        <Icon name="pv-plus" className="show" size={26} /> Show {moreOptions.length}{' '}
                        more
                    </button>
                ) : initialOptions.length > 7 ? (
                    <button
                        onClick={onShowPress.bind(this, index, expanded)}
                        className={Styles.showButton}
                    >
                        <Icon name="pv-minus" className="show" size={26} /> Show less
                    </button>
                ) : null}
            </div>
        );
    };

    return (
        <div className={classNames(Styles.filterDrawer, { drawerVisible })}>
            <div className={Styles.header}>
                <h4 className="upper">filter workouts</h4>
                <Icon name="pv-close" size={18} onClick={toggleDrawer} />
            </div>

            <div className={Styles.body}>{filters.map(Menu)}</div>

            <div className={Styles.footer}>
                <div className={Styles.footerButton} onClick={scrollToLibrary}>
                    <Button size="medium" fluid onClick={toggleDrawer}>
                        {!(filterCount || count)
                            ? 'Show Categories'
                            : count
                                ? `Show ${count} workouts`
                                : 'No matches'}
                    </Button>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <div className={Styles.footerButton}>
                    <Button
                        size="medium"
                        fluid
                        onClick={clearFilters}
                        secondary
                        disabled={!filterCount}
                    >
                        Clear Filters
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default WorkoutFiltersDrawer;
