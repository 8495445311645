import React, { useState, useRef } from 'react';
import cn from 'classnames';

import WorkoutFiltersBar from 'src/components/workout-library/WorkoutFiltersBar';
import WorkoutFiltersDrawer from 'src/components/workout-library/WorkoutFiltersDrawer';
import Pills from 'src/components/workout-library/Pills';
import { useStaticQuery, graphql } from 'gatsby';
import { cloneDeep, remove, includes, values, flatten, map } from 'lodash';

import { flattenGraphQLEdges } from 'src/utils/data-utils';
import { useBreakpoints, Breakpoints, Directions } from 'src/utils/breakpoint-utils';
import { Icon } from 'src/components/shared';
import * as FiltersStyles from 'src/styles/filters.module.scss';
import { Button, Grid } from 'semantic-ui-react';

import {
    WORKOUT_DURATIONS,
    WORKOUT_LEVELS,
    SearchQuery,
} from '@pvolve/sdk/src/app/modules/content/actions';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import Actions from '@pvolve/sdk/src/app/actions';
import { connect, ConnectedProps } from 'react-redux';
import {
    IState,
    IFilterTypes,
    IFilterData,
    IFilterConfig,
    FilterItemsState,
} from '@pvolve/sdk/src/redux/selectors';

const connector = connect(
    (state: IState) => ({
        workoutFilter: PvolveSelectors.content.workoutFilter(state),
    }),
    {
        executeQuery: (query: SearchQuery | object) =>
            Actions.content.search.trigger({
                contentType: 'workout',
                query,
            }),
    }
);

interface WorkoutFiltersProps {
    count: number;
    drawerVisible: boolean;
    drawerOn: () => void;
    drawerOff: () => void;
    category?: string;
    isWhiteStyle?: boolean;
    isHiddenCounter?: boolean;
    Styles: React.CSSProperties;
}

/**
 * Reference Implementation on mobile: mobile/src/components/workouts/Filters.tsx
 */
const WorkoutFilters = ({
    count,
    workoutFilter,
    category,
    isWhiteStyle,
    isHiddenCounter,
    executeQuery,
    drawerOn,
    drawerOff,
    Styles,
    ...rest
}: WorkoutFiltersProps & ConnectedProps<typeof connector>) => {
    const isDesktop = useBreakpoints(Breakpoints.lg, Directions.up);
    const libraryRef = useRef();
    const {
        allContentfulBodyFocus,
        allContentfulEquipment,
        allContentfulInstructor,
        allContentfulWorkoutType,
    } = useStaticQuery(
        graphql`
            query FiltersQuery {
                allContentfulBodyFocus {
                    edges {
                        node {
                            label: name
                            id: contentful_id
                            order
                        }
                    }
                }
                allContentfulEquipment {
                    edges {
                        node {
                            label: name
                            id: contentful_id
                            order
                        }
                    }
                }
                allContentfulInstructor {
                    edges {
                        node {
                            label: name
                            id: contentful_id
                            order
                            active
                        }
                    }
                }
                allContentfulWorkoutType {
                    edges {
                        node {
                            label: name
                            id: contentful_id
                            order
                        }
                    }
                }
            }
        `
    );

    const bodyFocuses = flattenGraphQLEdges(allContentfulBodyFocus.edges);
    const equipment = flattenGraphQLEdges(allContentfulEquipment.edges);
    const instructors = flattenGraphQLEdges(allContentfulInstructor.edges);
    const workoutTypes = flattenGraphQLEdges(allContentfulWorkoutType.edges);

    const activeInstructors = instructors.filter((instructor) => instructor?.active);

    const scrollToLibrary = () => {
        libraryRef?.current.scrollIntoView({ behaviour: 'smooth' });
    };
    const filters: IFilterConfig[] = [
        {
            label: 'equipment',
            data: equipment,
            type: IFilterTypes.EQUIPMENT,
            hide: ['no-equipment'].filter((cat) => cat === category),
        },
        {
            label: 'level',
            data: Object.keys(WORKOUT_LEVELS).map((key) => ({ id: key, label: key })),
            type: IFilterTypes.LEVEL,
            hide: ['the-foundation'].filter((cat) => cat === category),
        },
        {
            label: 'duration',
            data: Object.keys(WORKOUT_DURATIONS).map((key, index) => {
                const label = key === '61' ? `${key}+ MIN` : `${key} MIN`;

                return { id: key, label, order: index };
            }),
            type: IFilterTypes.DURATION,
            hide: ['quick-boost'].filter((cat) => cat === category),
        },
        {
            label: 'type',
            data: workoutTypes,
            type: IFilterTypes.WORKOUT_TYPE,
            hide: [
                'the-foundation',
                'strength-and-sculpt',
                'cardio-burn',
                'recovery-and-stretch',
                'mat-definition',
                'womens-wellness',
                'tutorials',
            ].filter((cat) => cat === category),
        },
        {
            label: 'focus',
            data: bodyFocuses,
            type: IFilterTypes.BODY_FOCUS,
        },
        {
            label: 'trainer',
            data: activeInstructors,
            type: IFilterTypes.INSTRUCTOR,
        },
    ];

    const [activeItems, setActiveItems] = useState<FilterItemsState>(workoutFilter);

    const filterCount = flatten(values(activeItems)).length;

    const clearFilters = () => {
        setActiveItems({});
        executeQuery({});
    };

    const removeFilter = (filter: IFilterConfig, option: IFilterData) => {
        const newActiveItemsState: FilterItemsState = cloneDeep(activeItems);

        if (includes(newActiveItemsState[filter.type], option.id)) {
            remove(newActiveItemsState[filter.type], (n) => n.toString() === option.id);
        } else {
            newActiveItemsState[filter.type] = [...newActiveItemsState[filter.type], option.id];
        }

        const isFilterEmpty = Object.values(newActiveItemsState).every(
            (element) => element.length === 0
        );

        if (isFilterEmpty) {
            clearFilters();
        } else {
            setActiveItems(newActiveItemsState);
            executeQuery(newActiveItemsState);
        }
    };

    const onMenuItemClick = (
        filter: IFilterConfig,
        option: IFilterData,
        e: React.MouseEvent<HTMLInputElement, MouseEvent>
    ) => {
        e.stopPropagation();

        const newActiveItemsState: FilterItemsState = cloneDeep(activeItems);

        if (newActiveItemsState[filter.type]) {
            removeFilter(filter, option);
        } else {
            newActiveItemsState[filter.type] = [option.id];
            setActiveItems(newActiveItemsState);
            executeQuery(newActiveItemsState);
        }
    };

    const getIsOptionActive = (filterType: IFilterTypes, optionId: string) => {
        return activeItems[filterType] && includes(activeItems[filterType], optionId)
            ? true
            : false;
    };

    const filterProps = {
        count: count,
        filters: filters,
        filterCount: filterCount,
        activeItems: activeItems,
        isWhiteStyle: isWhiteStyle,
        isHiddenCounter: isHiddenCounter,
        clearFilters: clearFilters,
        removeFilter: removeFilter,
        onMenuItemClick: onMenuItemClick,
        getIsOptionActive: getIsOptionActive,
        toggleDrawer: drawerOff,
        Styles: FiltersStyles,
        scrollToLibrary: scrollToLibrary,
    };

    return (
        <>
            {isDesktop ? (
                <WorkoutFiltersBar {...filterProps} />
            ) : (
                <div>
                    {!category && (
                        <>
                            <div ref={libraryRef} className={Styles.workoutHeader}>
                                <h1 className="upper bold stacked">Workout</h1>
                                <Icon
                                    name="pv-filters-horizontal-outline"
                                    size={34}
                                    onClick={drawerOn}
                                />
                            </div>
                            <div className="padding-horizontal--large">
                                <h1 className="accent">library</h1>
                            </div>
                        </>
                    )}
                    {!!count && (
                        <Grid.Row>
                            <Grid.Column
                                className={
                                    category
                                        ? FiltersStyles.pillsCatalogMobile
                                        : FiltersStyles.pillsMobile
                                }
                                textAlign="left"
                                width={16}
                            >
                                {map(activeItems, (item, type: string, index) => {
                                    return (
                                        <Pills
                                            key={`pill-${index}`}
                                            activeFilter={item}
                                            type={type}
                                            removeFilter={removeFilter}
                                            filters={filters}
                                        />
                                    );
                                })}
                            </Grid.Column>
                            <Grid
                                className={cn(FiltersStyles.statusRow, 'Xcontainer', 'margin--0')}
                            >
                                <Grid.Column className="padding--0" width={16}>
                                    {filterCount > 1 && (
                                        <Button
                                            className={FiltersStyles.clearButton}
                                            onClick={clearFilters}
                                        >
                                            Clear All Filters
                                        </Button>
                                    )}
                                </Grid.Column>
                                {!isHiddenCounter && (
                                    <Grid.Column className="padding--0" width={16}>
                                        <p>{count} workouts</p>
                                    </Grid.Column>
                                )}
                            </Grid>
                        </Grid.Row>
                    )}
                </div>
            )}
            {<WorkoutFiltersDrawer {...filterProps} {...rest} />}
        </>
    );
};

export default connector(WorkoutFilters);
